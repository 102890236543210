import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/cambiarclave',
        name: 'cambiarclave',
        component: () => import('./pages/ObtenerClave.vue'),
    },
    {
        path: '/solicitarcuenta',
        name: 'solicitarcuenta',
        component: () => import('./pages/SolicitarCuenta.vue'),
    },
    {
        path: '/aprobarcuenta',
        name: 'aprobarcuenta',
        component: () => import('./pages/AprobarCuenta.vue'),
    }, {
        path: '/rundesconocido',
        name: 'rundesconocido',
        component: () => import('./pages/RunDesconocido.vue'),
    }, {
        path: '/listadeusuarios',
        name: 'listadeusuarios',
        component: () => import('./pages/ListaDeUsuarios.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
