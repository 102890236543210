import { ref } from 'vue'
import axios from 'axios'
import { createClient } from '@supabase/supabase-js'
import jwt_decode from "jwt-decode";
import * as Sentry from "@sentry/vue";
export default class GapiFirebase {
    #access_token = null;
    #id_token = null;
    #expired = null;

    constructor() {
        this.isDevelopment = (process.env.NODE_ENV == "development");
        this.islogin = ref(false)
        this.supabase = createClient("https://daucfzwytvdmvuklwoyu.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYzMjkyNzk5NSwiZXhwIjoxOTQ4NTAzOTk1fQ.cIQUlM2Unbal63zTpcjmfA-vbf9xB205VZwFLY4QeiI")
        this.nombre = ref('Invitado')
        this.correo = ref('Desconocido')
        this.toast = ref(null)
        this.tipo = ref('Invitado')
        this.photoURL = ref('/assets/layout/images/profile.png')
        this.permisos = ref([])
        this.run = ref(null)
        this.api = axios.create({
            timeout: 10000
        })
        this.api.interceptors.request.use(async function (config) {
            console.log(config)
            if (this.islogin.value)
                config.headers.auth = await this.getIdToken()
            return config;
        }.bind(this), function (error) {
            return Promise.reject(error);
        });
        this.gapi = axios.create({
            timeout: 10000
        })
        this.gapi.interceptors.request.use(async function (config) {
            if (this.islogin.value)
                config.headers.Authorization = `Bearer ${await this.getAccessToken()}`
            return config;
        }.bind(this), function (error) {
            return Promise.reject(error);
        });

    }

    log(info, tema) {
        if (process.env.NODE_ENV == "development") {
            this.consola(info, `Subida a Sentry (Modulo: ${tema})`)
        } else {
            Sentry.captureException(info, {
                tags: {
                    modulo: tema,
                },
            });
        }
    }

    consola(mensaje, tema = null) {
        if (process.env.NODE_ENV == "development") {
            console.log("\n")
            if (tema) console.log(`Tema: ${tema}`)
            console.log(mensaje)
            console.log("\n")
        }
    }
    async logout() {
        localStorage.removeItem('cuentalogeada');
        localStorage.removeItem('expiredgapi');
        localStorage.removeItem('access_tokengapi');
        localStorage.removeItem('id_tokengapi');
        this.islogin.value = false
        this.nombre.value = "Invitado"
        this.correo.value = "Desconocido"
        this.photoURL.value = "/assets/layout/images/profile.png"
        this.permisos.value = []
        this.tipo.value = "Invitado"
        this.run.value = null
        this.supabase.auth.signOut()
    }

    popupWindow(url, windowName, w, h, win = window) {
        const y = win.top.outerHeight / 2 + win.top.screenY - (h / 2);
        const x = win.top.outerWidth / 2 + win.top.screenX - (w / 2);
        return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    }

    async getAccessToken() {
        if (Date.now() > this.#expired) {
            await this.login()
            return this.#access_token
        } else {
            return this.#access_token
        }
    }

    async getIdToken() {
        if (Date.now() > this.#expired) {
            await this.login()
            return this.#id_token
        } else {
            return this.#id_token
        }
    }

    async login() {
        var access_token_guardado = localStorage.getItem('access_tokengapi');
        var email_guardado = localStorage.getItem('cuentalogeada');
        var id_token_guardado = localStorage.getItem('id_tokengapi');
        var expiredgapi_guardado = localStorage.getItem('expiredgapi');

        var tokens;
        var win;

        let refreshsupabase;

        if (expiredgapi_guardado && expiredgapi_guardado > Date.now()) {
            tokens = {
                access_token: access_token_guardado,
                id_token: id_token_guardado,
                expired: expiredgapi_guardado
            }
        } else {
            let urlauth = new URL("https://accounts.google.com/o/oauth2/v2/auth")
            if (email_guardado) urlauth.searchParams.set('login_hint', email_guardado)
            else urlauth.searchParams.set('hd', 'cicpolmue.cl')
            urlauth.searchParams.set('scope', "openid profile email https://www.googleapis.com/auth/drive")
            urlauth.searchParams.set('response_type', 'token id_token')
            urlauth.searchParams.set('nonce', 'o3noi3r')
            urlauth.searchParams.set('client_id', '38489111246-5ohia760eoaqn80gcaf93mv7ltqd5hkh.apps.googleusercontent.com')
            var redirectURL = new URL((new URL(window.document.baseURI).origin))
            redirectURL.pathname = "/token.html"
            redirectURL = redirectURL.toString()
            urlauth.searchParams.set('redirect_uri', redirectURL)
            console.log(urlauth.toString())

            win = this.popupWindow(urlauth.href, 'windowname1', 600, 600)

            if (!win) {
                return
            }
            tokens = await new Promise(function (resolve) {
                let timer = setInterval(function () {
                    try {
                        let urlresponse = new URL(win.document.URL)
                        if (urlresponse.pathname == "/token.html") {
                            window.clearInterval(timer);
                            win.stop()
                            const parsedHash = new URLSearchParams(
                                urlresponse.hash.substr(1)
                            );
                            let tokens = {
                                access_token: parsedHash.get('access_token'),
                                id_token: parsedHash.get('id_token'),
                                expired: Date.now() + (parsedHash.get('expires_in') - 200) * 1000
                            }
                            if (tokens.access_token && tokens.id_token && tokens.expired) {
                                resolve(tokens)
                            } else {
                                resolve(null)
                            }
                        }
                    } catch (e) {
                        if (win.closed) {
                            window.clearInterval(timer);
                            resolve(null)
                        }
                    }
                }.bind(this), 100);
            }.bind(this))
            if (tokens)
                email_guardado = jwt_decode(tokens.id_token).email
        }

        if (this.supabase.auth.session()?.user?.email != email_guardado) {
            win.location.href = `${new URL((new URL(window.document.baseURI).origin))}/api/supabase/auth/v1/callback?login_hint=${email_guardado}`;
            refreshsupabase = await new Promise(function (resolve) {
                let timer = setInterval(function () {
                    try {
                        let urlresponse = new URL(win.document.URL)
                        if (urlresponse.pathname == "/token2.html") {
                            window.clearInterval(timer);
                            let urlresponse = new URL(win.document.URL)
                            win.close()
                            const parsedHash = new URLSearchParams(
                                urlresponse.hash.substr(1)
                            );
                            let response = parsedHash.get('refresh_token')
                            if (response) {
                                resolve(response)
                            } else {
                                resolve(null)
                            }
                        }
                    } catch (e) {
                        if (win.closed) {
                            window.clearInterval(timer);
                            resolve(null)
                        }
                    }
                }.bind(this), 100);
            }.bind(this))
            if (refreshsupabase)
                await this.supabase.auth.signIn({
                    refreshToken: refreshsupabase,
                });
        } else {
            refreshsupabase = true;
            try {
                win.close()
            } catch (error) {
                console.log("Por favor cierre la ventana")
            }
        }

        try {
            if (!tokens || !refreshsupabase) throw "Error de inicio"
            this.islogin.value = true
            this.#access_token = tokens.access_token
            this.#id_token = tokens.id_token
            this.#expired = tokens.expired
            const { data, error } = await this.supabase
                .rpc('yo')
            if (error) throw error;
            if (data.length == 0) throw { texto: "No se encontro el correo" }
            let yo = data[0]
            this.nombre.value = `${yo.nombres.split(' ')[0]} ${yo.apellidos.split(' ')[0]}`
            this.correo.value = yo.correo
            this.permisos.value = []
            this.tipo.value = yo.tipo
            this.run.value = yo.run
            this.photoURL.value = jwt_decode(tokens.id_token).picture
            localStorage.setItem('cuentalogeada', email_guardado);
            localStorage.setItem('access_tokengapi', tokens.access_token);
            localStorage.setItem('id_tokengapi', tokens.id_token);
            localStorage.setItem('expiredgapi', tokens.expired);
        } catch (error) {
            if (error.texto) this.toast.value = { mensaje: error.texto }
            this.islogin.value = false
            this.nombre.value = "Invitado"
            this.correo.value = "Desconocido"
            this.photoURL.value = "/assets/layout/images/profile.png"
            this.permisos.value = []
            this.tipo.value = "Invitado"
            this.run.value = null
            if (window.navigator.onLine) {
                await this.logout()
            }
        }
    }

    async init() {
        if (this.isDevelopment) {
            window.globalThis.$api = this
            window.supabase = this.supabase
        }
        if (window.localStorage.getItem('cuentalogeada')) await this.login()
    }
}