<template>
	<div class="layout-footer">
		<span class="footer-text" style="margin-right: 5px">Página en desarrollo si tiene problemas contacte al <a href="https://wa.me/56952003788">WhatsApp +56 9 5200 3788</a></span>
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>

<style scoped>

</style>