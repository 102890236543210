<template>
  <div class="layout-profile">
    <div>
      <Avatar :image="$api.photoURL.value" size="large" shape="circle" />
    </div>
    <div class="layout-profile-link">
      <span class="username">{{ $api.nombre.value }}</span>
    </div>
    <br />
    <div v-if="$api.islogin.value" class="layout-profile-link">
      <span class="username">{{ $api.correo.value }}</span>
    </div>
    <ul>
      <!-- <li>
        <button v-if="login" class="p-link">
          <i class="pi pi-fw pi-user"></i><span>Administrar mi cuenta</span>
        </button>
      </li> -->
      <li>
        <button class="p-link" v-if="$api.islogin.value" @click="$api.logout()">
          <i class="pi pi-fw pi-power-off"></i><span>Cerrar Sesión</span>
        </button>
        <button class="p-link" v-else @click="$api.login()">
          <i class="pi pi-fw pi-power-off"></i><span>Iniciar Sesión</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<style scoped>
</style>