 <template>
  <div class="p-grid p-fluid dashboard">
    <h1>En contrucción</h1>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  created() {
    if (process.env.NODE_ENV == "production")
      window.location.href = "https://www.cicpolmue.cl";
  },
};
</script>